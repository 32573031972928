import i18n from 'i18next'
import translationsES from '../locales/es/translations.json'
import translationsEN from '../locales/en/translations.json'
import translationsFR from '../locales/fr/translations.json'
import translationsRU from '../locales/ru/translations.json'

const resources = {
  es: {
    translations: translationsES
  },
  en: {
    translations: translationsEN
  },
  fr: {
    translations: translationsFR
  },
  ru: {
    translations: translationsRU
  }
};

i18n
  .init({
  	resources,
  	ns: ["translations"],
    defaultNS: "translations",
  	lng: 'en',
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react!!
    }
  });


export default i18n;